import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div style={{
      "display": "flex"
    }} id="intro-container">
      <div style={{
        "flex": "0.4",
        "paddingTop": "17px"
      }} id="intro-image">
    <img style={{
          "width": "100%",
          "borderRadius": "10px",
          "height": "auto"
        }} src="me-intro.png" />    
      </div>
      <div style={{
        "flex": "3",
        "marginLeft": "50px"
      }} id="intro-text">
    <h1>
    Hey there! 👋🏼<br />I'm Orhan Sönmez.
    </h1>
    <p>
    A full-stack software engineer and UI/UX designer. 🥷<br />
    Specialized in ⚛️ React, React-Native and 🖥️ Frontend development.
    </p>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      