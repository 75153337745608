import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectGrid = makeShortcode("ProjectGrid");
const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Clients`}<br /></h2>
    <div style={{
      "display": "flex",
      "width": "100%"
    }} id="clients-container">
  <div style={{
        "flex": "0.5",
        "marginRight": "80px"
      }}>
    <p>Here is a small selection of clients I had the pleasure to work with. 💖<br /><br />You can also download my <a href="Orhan-Soenmez-CV.pdf" target="_blank">CV/Portfolio</a> for more information on my work up to now.</p>
  </div>
  
  <div style={{
        "flex": "1",
        "paddingTop": "0.5rem"
      }} id="clients-grid-container">
   <ProjectGrid style={{
          gridGap: "3rem"
        }} mdxType="ProjectGrid">
    <ProjectCard title="Klarna" link="https://www.klarna.com" bg="#FFAEBC" logo="clients-klarna.png" mdxType="ProjectCard" />
    <ProjectCard title="Urban Sports Club" link="https://www.urbansportsclub.com" bg="#000000" logo="clients-urbansportsclub.png" style={{
            padding: "9% !important"
          }} mdxType="ProjectCard" />
    <ProjectCard title="Postbank" link="https://www.postbank.de" bg="#FFCD00" logo="clients-postbank.png" style={{
            padding: "9% !important"
          }} mdxType="ProjectCard" />
    <ProjectCard title="adidas" link="https://www.adidas.com" bg="#000000" logo="clients-adidas.png" style={{
            padding: "8% !important"
          }} mdxType="ProjectCard" />
    <ProjectCard title="Leica" link="https://leica-camera.com/" bg="#FFFFFF" logo="clients-leica.png" style={{
            padding: "6% !important"
          }} mdxType="ProjectCard" />
    <ProjectCard title="Leica" link="https://www.telekom.com/" bg="#E2007E" logo="clients-telekom.png" style={{
            padding: "4% !important"
          }} mdxType="ProjectCard" />
    
    </ProjectGrid>
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      