import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Write me a letter 💌`}</h2>
    <p>{`.. or maybe just write me an `}<a parentName="p" {...{
        "href": "mailto:orhan@sonmez.xyz"
      }}>{`Email`}</a>{` or shoot me a message on any of my socials, like `}<a parentName="p" {...{
        "href": "https://www.twitter.com/sonmez_xyz"
      }}>{`Twitter`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/orhan.soenmez"
      }}>{`Instagram`}</a>{` & `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/orhansoenmez/"
      }}>{`LinkedIn`}</a>{`. You can also find me on `}<a parentName="p" {...{
        "href": "https://www.github.com/orhan"
      }}>{`Github`}</a>{` of course.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      