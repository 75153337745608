import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div style={{
      "display": "flex"
    }} id="about-container">
      <div style={{
        "flex": "0.4",
        "paddingTop": "17px"
      }} id="about-image">
    <img style={{
          "width": "100%",
          "borderRadius": "10px",
          "height": "auto"
        }} src="me-about.png" />    
      </div>
      <div style={{
        "flex": "3",
        "marginLeft": "50px"
      }}>
        <h2>Hi again!</h2>
        <p>I'm Orhan. 🙋🏻‍♂️<br />
.. and I'm currently based in Berlin, Germany and I love working on exciting projects, but well, who doesn't? 🤗<br />
          <p>{`I've been a freelancer for a couple of years now and my fields of expertise are React (including React-Native) and all kinds of Frontend development. I also do UI-/UX-Design - That's right, I design & code. 🥷🏻`}<br /></p>
          <p>{`I have worked on several projects for high-profile companies as a freelancer and also co-founded and/or worked for some fresh startups in recent years. ✨`}<br /></p>
          <p>{`Want to learn more?`}<br />{`
I'm always up for a chat, just contact me over any of the social platforms you can find me on! 🤝🏻`}</p>
        </p>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      